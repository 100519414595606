.container {
   background-color: transparent;
   a {
      text-decoration: unset;
   }
}

   
.legal {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 80px;
   color: white;
   background-color: rgb(43,43,43);
   gap: 1rem;
  
   span {
      color: white;
   }
}