.container {
   position: sticky;
   top: 0;
   display: flex;
   height: 55px;
   padding: 0.5rem;
   padding-left: 3rem;
   background-color: var(--primary-brown); 
   z-index: 1000;
   box-shadow: 0 1px 10px 0 rgba(0,0,0,.2);

   .title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      margin-left: 1rem;
      color: white;
      font-size: 1.5rem;
   }
}

