.container {
    width: 100%;
    height: 100%;
    display: flex;
  }
  
  .loading {
    display: flex;
    justify-content: center;
    margin: auto;
  
    div {
      width: 1rem;
      height: 1rem;
      margin: 2rem 0.3rem;
      background: #6b4f1f;
      border-radius: 50%;
      -webkit-animation: bounce 0.9s  infinite alternate;
      animation: bounce 0.9s  infinite alternate;
    }
    #dot2 {
      animation-delay: .3s;
    }
    #dot3 {
      animation-delay: .6s;
    }
  }
  
    
  
  @-webkit-keyframes bounce {
      to {
          opacity: 0.3;
          -webkit-transform: translate3d(0, -1rem, 0);
          -ms-transform: translate3d(0, -1rem, 0);
          transform: translate3d(0, -1rem, 0);
      }
  }
  
  @keyframes bounce {
      to {
          opacity: 0.3;
          -webkit-transform: translate3d(0, -1rem, 0);
          -ms-transform: translate3d(0, -1rem, 0);
          transform: translate3d(0, -1rem, 0);
      }
  } 