@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400&family=Inter:wght@400;500&display=swap');

html {
  height: 100%;
  background: var(--theme-primary-bg);
}

body {
  min-height: 100vh;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

#root {
  min-height: 100vh;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.light-theme {
	--primary-color: #000000;
	--secondary-color: #2F3237;
	--tertiary-color: #666666;
	--quaternary-color: #DDDDDD;
	--quinary-color: #AFAFAF;

	--primary-bg: #FFFFFF;
	--secondary-bg: #F5F8FA;

	--editor-bg: #FFFFFF;
	--editor-color: #0E101A;
	--editor-selection: #DBEBFF;
	--loading-bg: linear-gradient(90deg, #f1f2f5, #FAFBFF 260px, #F1F2F5 520px);

	--file-bg: #FFFFFF;

	--thematic-break-color: #F0F2FC;

	--primary-boxShadow: 0 1px 8px rgba(109, 117, 141, .18);
	--secondary-boxShadow: 0 1px 5px rgba(0, 0, 0, .1);

	--optionSelected-bg: #02B3E4AA;
	--optionSelected-color: #FFFFFF;

	--miniTooltip-bg: #F1F1F1CC;

	--sidebarModal-bg: rgba(0, 0, 0, 0.05);

	transition: background-color 1s;
}

.dark-theme {


	transition: background-color 1s;
}

:root {
  /* Colors */
  --theme-primary-bg: #fcf9f4;
  --primary-brown: #6b4f1f;
  
	/* Gradients */
}

.pageContainer {
	display: flex;
	flex-direction: column;
	/* width: 100%;
	max-width: 1333px;
	margin-left: auto;
	margin-right: auto; */
}