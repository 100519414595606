@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@400&family=Inter:wght@400;500&display=swap);
html {
  height: 100%;
  background: #fcf9f4;
  background: var(--theme-primary-bg);
}

body {
  min-height: 100vh;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

#root {
  min-height: 100vh;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.light-theme {
	--primary-color: #000000;
	--secondary-color: #2F3237;
	--tertiary-color: #666666;
	--quaternary-color: #DDDDDD;
	--quinary-color: #AFAFAF;

	--primary-bg: #FFFFFF;
	--secondary-bg: #F5F8FA;

	--editor-bg: #FFFFFF;
	--editor-color: #0E101A;
	--editor-selection: #DBEBFF;
	--loading-bg: linear-gradient(90deg, #f1f2f5, #FAFBFF 260px, #F1F2F5 520px);

	--file-bg: #FFFFFF;

	--thematic-break-color: #F0F2FC;

	--primary-boxShadow: 0 1px 8px rgba(109, 117, 141, .18);
	--secondary-boxShadow: 0 1px 5px rgba(0, 0, 0, .1);

	--optionSelected-bg: #02B3E4AA;
	--optionSelected-color: #FFFFFF;

	--miniTooltip-bg: #F1F1F1CC;

	--sidebarModal-bg: rgba(0, 0, 0, 0.05);

	transition: background-color 1s;
}

.dark-theme {


	transition: background-color 1s;
}

:root {
  /* Colors */
  --theme-primary-bg: #fcf9f4;
  --primary-brown: #6b4f1f;
  
	/* Gradients */
}

.pageContainer {
	display: flex;
	flex-direction: column;
	/* width: 100%;
	max-width: 1333px;
	margin-left: auto;
	margin-right: auto; */
}
.Footer_container__3nRUm{background-color:transparent}.Footer_container__3nRUm a{text-decoration:unset}.Footer_legal__bSYbY{display:flex;justify-content:center;align-items:center;height:80px;color:white;background-color:#2b2b2b;grid-gap:1rem;gap:1rem}.Footer_legal__bSYbY span{color:white}

.Logo_logo__3IG6m{border-radius:50%;border:solid 1px white;display:flex;justify-content:center;align-items:center}.Logo_logo__3IG6m .Logo_wolf__2Tt2k{width:50%}

.Navbar_container__UB5Ik{position:-webkit-sticky;position:sticky;top:0;display:flex;height:55px;padding:0.5rem;padding-left:3rem;background-color:var(--primary-brown);z-index:1000;box-shadow:0 1px 10px 0 rgba(0,0,0,0.2)}.Navbar_container__UB5Ik .Navbar_title__4ph7a{display:flex;justify-content:center;align-items:center;height:50px;margin-left:1rem;color:white;font-size:1.5rem}

.Loading_container__3DxKb{width:100%;height:100%;display:flex}.Loading_loading__3SgGS{display:flex;justify-content:center;margin:auto}.Loading_loading__3SgGS div{width:1rem;height:1rem;margin:2rem 0.3rem;background:#6b4f1f;border-radius:50%;animation:Loading_bounce__c0-YM 0.9s  infinite alternate}.Loading_loading__3SgGS #Loading_dot2__1tsWG{animation-delay:.3s}.Loading_loading__3SgGS #Loading_dot3__384mg{animation-delay:.6s}@keyframes Loading_bounce__c0-YM{to{opacity:0.3;transform:translate3d(0, -1rem, 0)}}

