    .logo {
       border-radius:50%;
       border:solid 1px white;
       display:flex;
       justify-content:center;
       align-items:center;
    
       .wolf {
          width:50%;
       }
    }